.footer {
  background-color: var(--gray);
  height: 10vh;
  display: grid;
  place-content: center;
  text-align: center;
  padding-inline: 1rem;
}
.footer-p {
  width: 100%;
  color: var(--newwhite);
}
