.owner-hero {
  background-image: url("../../../media/5.jpg");
  background-position: top;
}

.img-half-owner {
  background-image: url("../../../media/owner-half.jpg");
}
.newwhite {
  color: var(--newwhite);
}
@media screen and (max-width: 800px) {
  .blue-mob-hero {
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 150, 0.3);
  }
}
