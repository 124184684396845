.na-hero {
  background-image: url("../../../media/na2.jpeg");
  background-position: 30% 70%;
}
.data-half-na {
  background-color: whitesmoke;
}
.img-half-na {
  background-image: url("../../../media/na4.jpg");
  background-position: 50% 70%;
}
