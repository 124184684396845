:root,
body,
.App {
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  overflow-x: hidden;
}
:root {
  --primary: whitesmoke;
  --darkblue: white;
  --yellow: gray;
  --yellowHover: rgba(88, 86, 86, 0.846);
  --white: rgb(0, 0, 0);
  --black: #0000;
  --newwhite: #fff;
  --gray: #202020;
  --lightgray: #494949;
  --neworange: #fd9800;
  --h1D: 3.75rem;
  --h1M: 2rem;
  --h2: 1rem;
  --p: 16px;
}
h2 {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
