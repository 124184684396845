.content-service {
  max-width: 100vw;
}
.service {
  height: 100vh;
  display: flex;
}
.service > * {
  flex: 1;
}
.service-l {
  height: 100vh;
  background-size: cover;
  transition: all 0.2s;
}
.service-l-img {
  background-color: var(--newwhite);
}
.ser-data {
  display: grid;
  padding-top: 40%;
  width: 100%;
  margin-inline: AUTO;
  row-gap: 15px;
  place-content: center;
  text-align: center;
}
.ser-data > p {
  padding-inline: 10%;
  height: 5rem;
}
.ser-data > button {
  margin: auto;
}
.ser-heading {
  position: absolute;
  padding-top: 12.5vh;
  padding-left: 1vw;
  text-shadow: black 1px 1px;
  color: var(--neworange);
}
.ser-h2 {
  color: var(--newblack);
}
.service-r {
  height: 100vh;
  background-color: whitesmoke;
  background-size: cover;
  transition: all 0.2s;
}
.ser-heading-mob {
  display: none;
}
@media screen and (max-width: 800px) {
  .service {
    display: grid;
    height: auto;
    max-width: 100vw;
  }
  .ser-heading {
    display: none;
  }
  .ser-heading-mob {
    display: grid;
    height: 15vh;
    width: 100%;
    align-content: center;
    justify-content: center;
    text-transform: uppercase;
    color: var(--newblack);
  }
  .ser-data > p {
    padding-inline: 0;
  }
  .ser-overlay,
  .service-l,
  .service-r {
    height: 40vh;
    background-position: 0% 30%;
    margin: 5%;
    border: var(--yellow) 2px solid;
  }
  .ser-data {
    padding-top: 15%;
    row-gap: 10px;
  }
  .p-hidden {
    display: none;
  }
}
