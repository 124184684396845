.content {
  max-width: 100vw;
}
.hero {
  background-size: cover;
  height: 100vh;
}
.homepage-hero {
  background-image: url("../../media/na2.jpeg");
  background-position: 30% 70%;
}
.hero-adjust {
  padding-top: 25vh;
  padding-left: 5vw;
  display: grid;
  row-gap: 1.5rem;
  width: 80%;
}
.left {
  width: 82.5vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  float: right;
  justify-content: center;
  text-align: right;
  row-gap: 20px;
}
.heading-small {
  font-size: calc(var(--h1M) * 0.75);
}
p {
  margin: 0;
  color: var(--white);
}
.hero-btn {
  border: none;
  background-color: var(--yellow);
  border-radius: 1.43rem;
  width: 15rem;
  height: 2.5rem;
  color: var(--primary);
  font-size: var(--h2);
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.2s;
  cursor: pointer;
}
.hero-btn:hover {
  background-color: var(--white);
}
.halves {
  display: flex;
  height: 100vh;
}
.data-half {
  flex: 1;
  display: grid;
  place-items: center;
}
.data-half > div {
  width: 80%;
  display: grid;
  row-gap: 2rem;
}
.buttons {
  display: flex;
  justify-content: space-between;
}
.img-half {
  flex: 1;
  background-size: cover;
}

.data-half-1 {
  background-color: var(--primary);
}
.img-half-1 {
  background-image: url("../../media/3.jpg");
  background-position: 60% 70%;
}
.data-half-2 {
  background-color: var(--newwhite);
}
.img-half-2 {
  background-image: url("../../media/sky1.jpg");
  background-position: 20% 50%;
}

@media screen and (max-width: 1000px) {
  .hero {
    background-position: center;
    height: 87.5vh;
  }
  .hero-adjust {
    padding-top: 10vh;
  }
  .halves {
    display: flex;
    flex-direction: column;
    min-height: 140vh;
  }
  .data-half {
    height: 70vh;
  }
  .img-half {
    height: 70vh;
  }
  .img-half-1 {
    background-position: bottom;
  }

  .halves-2 {
    flex-direction: column-reverse;
  }
  .buttons {
    display: grid;
    width: auto;
    place-items: center;
    row-gap: 1rem;
  }
  .left {
    width: 90vw;
  }
}
