.ser-data-network {
  padding-top: 70%;
}
.ser-data-network > .p-hidden {
  min-height: 20vh;
}
.net-heading {
  pointer-events: none;
  color: var(--neworange);
  padding-top: 12.5vh;
  padding-left: 1vw;
}
.network-img-customer {
  background-color: whitesmoke;
}
.network-img-agent {
  background-color: var(--newwhite);
}
.network-img-owner {
  background-color: whitesmoke;
  background-size: cover;
}
.network-img-carrier {
  background-color: var(--newwhite);
}

@media screen and (max-width: 800px) {
  .ser-data-network {
    padding-top: 10%;
  }
}
