.halves-form {
  display: grid;
  grid-template-columns: 50% 50%;
}
.customer-half {
  background-color: whitesmoke;
  margin-inline: auto;
  color: black;
  border: 1px black solid;
  padding: 5% 10%;
  width: 70%;
  row-gap: 20px;
  margin-bottom: 5%;
}
.form-heading {
  width: 100%;
  text-align: center;
}
.quote {
  background: var(--newwhite);
  padding-bottom: 5%;
}
.form {
  background-color: whitesmoke;
  margin-inline: auto;
  color: black;
  border: 1px black solid;
  padding: 5% 10%;
  width: 70%;
  display: grid;
  row-gap: 20px;
}
.form-row {
  display: grid;
  row-gap: 5px;
}
form {
  display: grid;
  row-gap: 10px;
}
label {
  font-weight: bold;
}
input {
  padding: 15px;
  font-size: var(--h2);
  border: 1px solid black;
}
input:focus,
input:active {
  border: 1px solid var(--primary);
}
.form > p {
  color: black;
}
.submit-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.submit-btn {
  cursor: pointer;
  background-color: var(--lightgray);
  border: none;
  font-size: var(--h2);
  text-transform: uppercase;
  font-weight: bold;
  color: var(--newwhite);
  width: 50%;
  place-content: right;
  border-radius: 1.43rem;
  text-align: center;
  transition: all 0.2s;
}
.submit-btn:hover,
.submit-btn:focus {
  background-color: var(--gray);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media screen and (max-width: 800px) {
  .halves-form {
    display: grid;
    grid-template-columns: auto;
  }
  .form {
    padding: 5% 5%;
    width: 80%;
  }
  .submit-btn {
    width: 70%;
  }
  .customer-half {
    padding-inline: 5%;
    width: 80%;
  }
}
